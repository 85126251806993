import React from 'react';

import Login from '../views/Login';
import PageTransition from '../components/PageTransition';
import routes from '../messages/routes';
import NotFoundPage from '../pages/404';
import SsoLoginSuccess from '../views/Login/SsoLoginSuccess';

const LoginPage = () => (
  <PageTransition basepath={'/'} showStepper={true} hideMenu>
    <Login path={routes.login} />
    <SsoLoginSuccess path={'sso-login'} />
    <NotFoundPage default />
  </PageTransition>
);

LoginPage.propTypes = {};

export default LoginPage;
