import { defineMessages } from 'react-intl';

export default defineMessages({
  seoTitle: {
    id: 'cc4.seo.personal-info.title',
    defaultMessage: '',
  },
  seoDescription: {
    id: 'cc4.seo.personal-info.description',
    defaultMessage: '',
  },
  cobrandingTitle: {
    id: 'cc4.form.personal-info.cobranding-title',
    defaultMessage: 'Welcome to {name} Claim Service!',
  },
  cobrandingUnsubscribeLink: {
    id: 'cc4.form.personal-info.cobranding-unsubscribe-link',
    defaultMessage: 'I want to unsubscribe from this service',
  },
  personalInfoCompensationTitle: {
    id: 'cc4.form.personal-info.compensation-title',
    defaultMessage: 'Good news! You may be entitled to {amount}',
  },
  personalInfoCompensationSubTitle: {
    id: 'cc4.form.personal-info.compensation-sub-title',
    defaultMessage: `Fill in your details and we'll get you compensated!`,
  },
  personalInfoTicketRefundTitle: {
    id: 'cc4.form.personal-info.ticket-refund-title',
    defaultMessage:
      'We believe you are entitled to the complete refund of your ticket!',
  },
  personalInfoTicketRefundSubTitle: {
    id: 'cc4.form.personal-info.ticket-refund-sub-title',
    defaultMessage: `Fill in your details and we’ll get you your refund!`,
  },
  passengerOne: {
    id: 'cc4.form.personal-info.passenger-one',
    defaultMessage: `Passenger 1 (you)`,
  },
  personalDetails: {
    id: 'cc4.form.personal-info.personal-details',
    defaultMessage: `Personal details`,
  },
  notPassenger: {
    id: 'cc4.form.personal-info.not-passenger',
    defaultMessage: `You are not a passenger? <cta>Click here</cta>`,
  },
  areYouPassenger: {
    id: 'cc4.form.personal-info.are-you-passenger',
    defaultMessage: `Are you a passenger on the disrupted flight? <cta>Click here</cta>`,
  },
  firstName: {
    id: 'cc4.form.personal-info.label.first-name',
    defaultMessage: 'First name',
  },
  firstNamePlaceholder: {
    id: 'cc4.form.personal-info.placeholder.first-name',
    defaultMessage: 'Your first name',
  },
  lastName: {
    id: 'cc4.form.personal-info.label.last-name',
    defaultMessage: 'Last name',
  },
  lastNamePlaceholder: {
    id: 'cc4.form.personal-info.placeholder.last-name',
    defaultMessage: 'Your last name',
  },
  phone: {
    id: 'cc4.form.personal-info.label.phone',
    defaultMessage: 'Phone',
  },
  phonePlaceholder: {
    id: 'cc4.form.personal-info.placeholder.phone',
    defaultMessage: 'Your phone number',
  },
  birthDate: {
    id: 'cc4.form.personal-info.label.birth-date',
    defaultMessage: 'Birth date',
  },
  address: {
    id: 'cc4.form.personal-info.label.address',
    defaultMessage: 'Address',
  },
  addressPlaceholder: {
    id: 'cc4.form.personal-info.placeholder.address',
    defaultMessage: 'Your street and house number',
  },
  postalCode: {
    id: 'cc4.form.personal-info.label.postal-code',
    defaultMessage: 'Postal code',
  },
  postalCodePlaceholder: {
    id: 'cc4.form.personal-info.placeholder.postal-code',
    defaultMessage: 'Your postal code',
  },
  city: {
    id: 'cc4.form.personal-info.label.city',
    defaultMessage: 'City',
  },
  cityPlaceholder: {
    id: 'cc4.form.personal-info.placeholder.city',
    defaultMessage: 'Your city',
  },
  cityOfBirth: {
    id: 'cc4.form.personal-info.label.city-of-birth',
    defaultMessage: 'City of Birth',
  },
  cityOfBirthPlaceholder: {
    id: 'cc4.form.personal-info.placeholder.city-of-birth',
    defaultMessage: 'Your city of birth',
  },
  placeOfBirth: {
    id: 'cc4.form.personal-info.label.place-of-birth',
    defaultMessage: 'Place of Birth',
  },
  placeOfBirthPlaceholder: {
    id: 'cc4.form.personal-info.placeholder.place-of-birth',
    defaultMessage: 'Your place of birth',
  },
  country: {
    id: 'cc4.form.personal-info.label.country',
    defaultMessage: 'Country',
  },
  countryPlaceholder: {
    id: 'cc4.form.personal-info.placeholder.country',
    defaultMessage: 'Search country',
  },
  nationalIdNumber: {
    id: 'cc4.form.personal-info.label.national-id-number',
    defaultMessage: 'National identification number',
  },
  nationalIdNumberPlaceholder: {
    id: 'cc4.form.personal-info.placeholder.national-id-number',
    defaultMessage: 'Your national identification number',
  },
  idDocumentNumber: {
    id: 'cc4.form.personal-info.label.id-document-number',
    defaultMessage: 'Identification document number',
  },
  idDocumentNumberPlaceholder: {
    id: 'cc4.form.personal-info.placeholder.id-document-number',
    defaultMessage: 'Your identification document number',
  },
  occupation: {
    id: 'cc4.form.personal-info.label.occupation',
    defaultMessage: 'Occupation',
  },
  occupationPlaceholder: {
    id: 'cc4.form.personal-info.placeholder.occupation',
    defaultMessage: 'Your occupation',
  },
  fiscalCode: {
    id: 'cc4.form.personal-info.label.fiscal-code',
    defaultMessage: 'Fiscal code',
  },
  fiscalCodePlaceholder: {
    id: 'cc4.form.personal-info.placeholder.fiscal-code',
    defaultMessage: 'Your fiscal code',
  },
  countryOfBirth: {
    id: 'cc4.form.personal-info.label.country-of-birth',
    defaultMessage: 'Country of Birth',
  },
  countryOfBirthPlaceholder: {
    id: 'cc4.form.personal-info.placeholder.country-of-birth',
    defaultMessage: 'Search country of birth',
  },
  termsAndConditions: {
    id: 'cc4.form.personal-info.label.not-a-passenger-terms-and-conditions',
    defaultMessage:
      'I agree to the <cta>Terms and Conditions</cta> and <prices>Pricing list</prices>',
  },
  personalInfoBackButton: {
    id: 'cc4.form.personal-info.button.back',
    defaultMessage: 'Back',
  },
  submitContinue: {
    id: 'cc4.form.personal-info.button.submit-continue',
    defaultMessage: 'Continue',
  },
  asideText: {
    id: 'cc4.form.personal-info.aside-text',
    defaultMessage:
      'Complete your claim now and our legal team will immediately start working to get you compensated!',
  },
  flightInfo: {
    id: 'cc4.form.personal-info.flight-info',
    defaultMessage: 'Flight: {flight_code} | Date: {flight_date}',
  },
  flightDetailsSubtitle: {
    id: 'cc4.form.personal-info.flight-details-subtitle',
    defaultMessage: 'Flight details',
  },
});
