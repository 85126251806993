import React from 'react';

import Loading from '../../components/Loading';

const SsoLoginSuccess = () => {
  return <Loading />;
};

SsoLoginSuccess.propTypes = {};

export default SsoLoginSuccess;
